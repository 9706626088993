import { Link } from 'react-router-dom';
import { Music } from 'lucide-react';
import AuthComponent from './AuthComponent';

export const Header = () => {
  return (
    <nav className="fixed w-full bg-white/80 backdrop-blur-md border-b border-purple-100 z-50">
      <div className="container mx-auto max-w-6xl px-6 h-16 flex items-center justify-between">
        {/* Logo Section */}
        <Link
          to="/"
          className="group flex items-center space-x-3 transition-transform duration-200 hover:-translate-y-1"
        >
          <div className="bg-purple-600 p-2 rounded-lg group-hover:shadow-md group-hover:bg-purple-700 transition-all duration-200">
            <Music className="h-5 w-5 text-white group-hover:scale-110 transition-transform duration-200" />
          </div>
          <span className="text-xl font-bold bg-gradient-to-r from-purple-600 to-purple-800 bg-clip-text text-transparent group-hover:from-purple-700 group-hover:to-purple-900 transition-all duration-200">
            allmygigs
          </span>
        </Link>

        {/* Authentication Section */}
        <AuthComponent />
      </div>
    </nav>
  );
};
