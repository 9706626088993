import React from 'react';
import { ExternalLink } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";

interface EventLinkProps {
    url: string;
}

const EventLink: React.FC<EventLinkProps> = ({ url }) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        variant="ghost"
                        className="w-full rounded-full bg-violet-100 hover:bg-violet-200 transition-colors group-hover:rotate-1 duration-300 px-3 py-1.5 h-auto text-sm font-medium justify-start"
                        onClick={() => window.open(url, '_blank', 'noopener noreferrer')}
                    >
                        <ExternalLink className="h-4 w-4 text-violet-600 mr-1.5 inline-block" />
                        View
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Go to event</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default EventLink;