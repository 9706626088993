import { Calendar, Clock, Link as LinkIcon, MapPin, Loader2, Globe } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { EventCreationResponse } from "@/types/api";
import { useAuth } from "@/components/AuthProvider";
import { CreateNewEvent } from "@/types/Strings";
import URLInput from "@/components/URLInput";
import { calculateEndTime, convertToUTC, getDefaultStartTime } from "@/lib/time_utils";


const EventCreationPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    if (!user || !user.username) {
        navigate("/");
    }

    const [formData, setFormData] = useState({
        title: "",
        venue: "",
        startDateTime: "",
        endDateTime: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        eventUrl: "",
        description: "",
        userId: "",
    });

    // Set default times on component mount
    useEffect(() => {
        const defaultStart = getDefaultStartTime();
        setFormData(prev => ({
            ...prev,
            startDateTime: defaultStart,
            endDateTime: calculateEndTime(defaultStart)
        }));
    }, []);

    const handleStartDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStartDateTime = e.target.value;
        setFormData(prev => ({
            ...prev,
            startDateTime: newStartDateTime,
            endDateTime: !prev.endDateTime || new Date(prev.endDateTime) <= new Date(newStartDateTime)
                ? calculateEndTime(newStartDateTime)
                : prev.endDateTime
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const formDataToSend = new FormData();

            // Convert times to UTC before sending
            const formattedData = {
                ...formData,
                startDateTime: convertToUTC(formData.startDateTime, formData.timezone),
                endDateTime: formData.endDateTime
                    ? convertToUTC(formData.endDateTime, formData.timezone)
                    : '',
            };

            Object.entries(formattedData).forEach(([key, value]) => {
                if (value) formDataToSend.append(key, value);
            });

            if (user) {
                formDataToSend.set("userId", String(user.id));
            }

            const response = await fetch("/api/create-event", {
                method: "POST",
                body: formDataToSend,
            });

            if (!response.ok) {
                const data: EventCreationResponse = await response.json();
                throw new Error(data.message || "Failed to create event");
            }

            setSuccess(true);
            setTimeout(() => {
                navigate(`/${user?.username}`);
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : "Something went wrong");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex flex-col items-center p-4">
            <div className="w-full max-w-2xl py-8">
                <Card className="border-0 shadow-lg">
                    <CardHeader className="text-center space-y-2">
                        <CardTitle className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                            {CreateNewEvent}
                        </CardTitle>
                        <CardDescription className="text-gray-600 text-lg">
                            Share your next performance with your followers
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Title */}
                            <div className="space-y-2">
                                <Label htmlFor="title" className="text-sm font-medium">
                                    Event Title
                                </Label>
                                <Input
                                    id="title"
                                    value={formData.title}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            title: e.target.value,
                                        }))
                                    }
                                    required
                                    placeholder="Give your event a catchy title"
                                    className="h-12 text-lg px-4 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                />
                            </div>

                            {/* Venue */}
                            <div className="space-y-2">
                                <Label htmlFor="venue" className="text-sm font-medium">
                                    Venue
                                </Label>
                                <div className="relative">
                                    <MapPin className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                    <Input
                                        id="venue"
                                        value={formData.venue}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                venue: e.target.value,
                                            }))
                                        }
                                        required
                                        placeholder="Where is your event taking place?"
                                        className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                    />
                                </div>
                            </div>

                            {/* Date/Time and Timezone */}
                            <div className="space-y-4">
                                <Label className="text-sm font-medium">Date & Time</Label>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {/* Start DateTime */}
                                    <div className="space-y-2">
                                        <Label htmlFor="startDateTime" className="text-xs text-gray-500">
                                            Start
                                        </Label>
                                        <div className="relative">
                                            <Calendar className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                            <Input
                                                id="startDateTime"
                                                type="datetime-local"
                                                value={formData.startDateTime}
                                                onChange={handleStartDateTimeChange}
                                                min={getDefaultStartTime()}
                                                required
                                                className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                            />
                                        </div>
                                    </div>

                                    {/* End DateTime */}
                                    <div className="space-y-2">
                                        <Label htmlFor="endDateTime" className="text-xs text-gray-500">
                                            End (Optional)
                                        </Label>
                                        <div className="relative">
                                            <Clock className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                            <Input
                                                id="endDateTime"
                                                type="datetime-local"
                                                value={formData.endDateTime}
                                                onChange={(e) =>
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        endDateTime: e.target.value,
                                                    }))
                                                }
                                                min={formData.startDateTime}
                                                className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Timezone */}
                                <div className="space-y-2">
                                    <Label htmlFor="timezone" className="text-sm font-medium">
                                        Timezone
                                    </Label>
                                    <div className="relative">
                                        <Globe className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5 z-10" />
                                        <Select
                                            value={formData.timezone}
                                            onValueChange={(value) =>
                                                setFormData((prev) => ({ ...prev, timezone: value }))
                                            }
                                        >
                                            <SelectTrigger className="h-12 pl-12 border-gray-200">
                                                <SelectValue placeholder="Select timezone" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {[
                                                    'America/New_York',
                                                    'America/Chicago',
                                                    'America/Denver',
                                                    'America/Los_Angeles',
                                                    'America/Anchorage',
                                                    'Pacific/Honolulu',
                                                    'Europe/London',
                                                    'Europe/Paris',
                                                    'Europe/Berlin',
                                                    'Europe/Moscow',
                                                    'Asia/Dubai',
                                                    'Asia/Shanghai',
                                                    'Asia/Tokyo',
                                                    'Australia/Sydney',
                                                    'Pacific/Auckland'
                                                ].map((tz) => (
                                                    <SelectItem key={tz} value={tz}>
                                                        {tz.replace(/_/g, ' ')}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>

                            {/* URLs */}
                            <div className="space-y-2">
                                <Label htmlFor="eventUrl" className="text-sm font-medium">
                                    Event URL (Optional)
                                </Label>
                                <div className="relative">
                                    <LinkIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                    <URLInput
                                        name="eventUrl"
                                        value={formData.eventUrl}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                [e.target.name]: e.target.value,
                                            }))
                                        }
                                        label="Event URL"
                                        required={false}
                                    />
                                </div>
                            </div>

                            {/* Description */}
                            <div className="space-y-2">
                                <Label htmlFor="description" className="text-sm font-medium">
                                    Description (Optional)
                                </Label>
                                <Textarea
                                    id="description"
                                    value={formData.description}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            description: e.target.value,
                                        }))
                                    }
                                    placeholder="Tell people more about your event..."
                                    className="h-32 resize-none border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                />
                            </div>

                            {error && (
                                <Alert variant="destructive" className="mt-4">
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}

                            {success && (
                                <Alert className="mt-4 bg-green-50 text-green-700 border-green-200">
                                    <AlertDescription>
                                        Event created successfully! Redirecting to your profile...
                                        ✨
                                    </AlertDescription>
                                </Alert>
                            )}

                            <Button
                                type="submit"
                                className="w-full h-12 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white transition-all duration-200 transform hover:scale-[1.02]"
                                disabled={
                                    isLoading ||
                                    !formData.title ||
                                    !formData.venue ||
                                    !formData.startDateTime ||
                                    !formData.timezone ||
                                    success
                                }
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <Loader2 className="w-5 h-5 animate-spin" />
                                        <span>Creating Event...</span>
                                    </div>
                                ) : (
                                    "Create Event"
                                )}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default EventCreationPage;