import React from 'react';
import { Music } from 'lucide-react';

const LoadingPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-purple-50 flex flex-col items-center justify-center">
            <div className="space-y-6 text-center">
                {/* Animated logo */}
                <div className="relative">
                    <div className="bg-purple-600 p-4 rounded-xl animate-pulse flex">
                        <Music className="h-8 w-8 text-white p-1" />
                        <div className="text-lg font-medium text-white">
                            allmygigs
                        </div>
                    </div>
                    {/* Animated rings */}
                    <div className="absolute inset-0 border-4 border-purple-200 rounded-xl animate-ping opacity-75"></div>
                </div>



                {/* Loading dots */}
                <div className="flex space-x-2 justify-center">
                    <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                    <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                    <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;

