export const protectedUsernames: readonly string[] = [
    // System and Administrative
    'admin',
    'administrator',
    'system',
    'root',
    'superuser',
    'moderator',
    'mod',

    // Authentication
    'login',
    'logout',
    'signin',
    'signout',
    'register',
    'signup',
    'account',
    'password',
    'reset',
    'verify',
    'profile',
    'settings',

    // Common Routes
    'api',
    'app',
    'blog',
    'careers',
    'cart',
    'checkout',
    'contact',
    'dashboard',
    'docs',
    'download',
    'downloads',
    'edit',
    'email',
    'faq',
    'feed',
    'forum',
    'forums',
    'help',
    'home',
    'inbox',
    'index',
    'legal',
    'mail',
    'main',
    'new',
    'news',
    'notifications',
    'oauth',
    'pricing',
    'privacy',
    'search',
    'secure',
    'security',
    'shop',
    'sitemap',
    'ssl',
    'staff',
    'status',
    'store',
    'support',
    'terms',
    'tos',
    'upload',
    'user',
    'users',
    'webhook',
    'webhooks',
    'www',

    // File Extensions
    'html',
    'htm',
    'php',
    'json',
    'xml',
    'pdf',

    // Special Characters
    'undefined',
    'null',
    'none',

    // Brand Protection
    'official',
    'real',
    'verified',
    'original',

    // System Reserved
    'test',
    'testing',
    'development',
    'staging',
    'production',
    'beta',
    'alpha',
    'localhost',

    // Brand related
    'allmygigs',
    'gigs'
] as const;

// Simple check function
export const isProtectedUsername = (username: string): boolean =>
    protectedUsernames.includes(username.toLowerCase());

export const PLATFORMS = [
    'Instagram',
    'Twitter',
    'YouTube',
    'TikTok',
    'Facebook',
    'LinkedIn',
    'Website',
];
