import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { AMGUser } from '@/types/api';

interface GoogleUserInfo {
    sub: string;
    name: string;
    email: string;
    picture: string;
    given_name?: string;
    family_name?: string;
    locale?: string;
    email_verified?: boolean;
}

interface AMGUserCheckResponse {
    exists: boolean;
    user?: AMGUser;
    error?: string;
}

interface AuthContextType {
    user: AMGUser | null;
    isAuthenticated: boolean;
    login: () => void;
    logout: () => void;
    isLoading: boolean;
    updateUserProfile: (updates: Partial<AMGUser>) => Promise<void>;
    setCurrentUser: (userData: AMGUser) => void;  // New function
}

const AUTH_STORAGE_KEY = 'auth_user';
const TOKEN_STORAGE_KEY = 'auth_token';
const TEMP_USER_KEY = 'temp_user';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<AMGUser | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const loadSavedAuth = () => {
            try {
                const savedUser = localStorage.getItem(AUTH_STORAGE_KEY);
                const savedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
                const tempUser = localStorage.getItem(TEMP_USER_KEY);

                if (savedUser && savedToken) {
                    setUser(JSON.parse(savedUser));
                } else if (tempUser) {
                    setUser(JSON.parse(tempUser));
                }
            } catch (error) {
                console.error('Error loading saved auth state:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadSavedAuth();
    }, []);

    const saveAuthState = (userData: AMGUser, token: string) => {
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(userData));
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
        localStorage.removeItem(TEMP_USER_KEY);
    };

    const saveTempUser = (userData: AMGUser) => {
        localStorage.setItem(TEMP_USER_KEY, JSON.stringify(userData));
    };

    const clearAuthState = () => {
        localStorage.removeItem(AUTH_STORAGE_KEY);
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        localStorage.removeItem(TEMP_USER_KEY);
    };

    // New function to set current user
    const setCurrentUser = (userData: AMGUser) => {
        setUser(userData);
        const token = localStorage.getItem(TOKEN_STORAGE_KEY);
        if (token) {
            // If we have a token, this is an authenticated user
            saveAuthState(userData, token);
        } else {
            // If no token, treat as temporary user
            saveTempUser(userData);
        }
    };

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                setIsLoading(true);
                const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const userInfo = await response.json() as GoogleUserInfo;
                const userCheckResponse = await getUserIfExists(userInfo.email);

                if (!userCheckResponse.exists || !userCheckResponse.user) {
                    const tempProfile: AMGUser = {
                        id: crypto.randomUUID(),
                        displayName: userInfo.name,
                        email: userInfo.email,
                        imageUrl: userInfo.picture,
                        username: '',
                    };
                    setCurrentUser(tempProfile);
                    navigate('/register-user');
                } else {
                    const profile: AMGUser = {
                        id: userCheckResponse.user.id,
                        displayName: userCheckResponse.user.displayName,
                        email: userInfo.email,
                        imageUrl: userInfo.picture,
                        username: userCheckResponse.user.username,
                        bio: userCheckResponse.user.bio,
                        createdAt: userCheckResponse.user.createdAt,
                        updatedAt: userCheckResponse.user.updatedAt
                    };
                    saveAuthState(profile, tokenResponse.access_token);
                    setUser(profile);
                    navigate(`/${profile.username}`);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                alert('Failed to get user information');
            } finally {
                setIsLoading(false);
            }
        },
        onError: (error) => {
            console.error('Login failed:', error);
            alert('Login failed. Please try again.');
            setIsLoading(false);
        },
        scope: 'email profile',
    });

    const logout = () => {
        setIsLoading(true);
        googleLogout();
        clearAuthState();
        setUser(null);
        navigate('/');
        setIsLoading(false);
    };

    const getUserIfExists = async (email: string): Promise<AMGUserCheckResponse> => {
        try {
            const response = await fetch(`/api/user-exists/${email}`);

            if (!response.ok) {
                throw new Error('Failed to check user status');
            }
            const data = await response.json() as AMGUserCheckResponse;
            return data;
        } catch (error) {
            console.error('Error checking user status:', error);
            return { exists: false };
        }
    };

    const updateUserProfile = async (updates: Partial<AMGUser>): Promise<void> => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem(TOKEN_STORAGE_KEY);

            if (!user) {
                throw new Error('No authenticated user');
            }

            const response = await fetch(`/api/users/${user.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    ...updates,
                    display_name: updates.displayName,
                    avatar_url: updates.imageUrl,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update profile');
            }

            const updatedUser = { ...user, ...updates };
            setCurrentUser(updatedUser);
        } catch (error) {
            console.error('Error updating profile:', error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated: !!user,
                login,
                logout,
                isLoading,
                updateUserProfile,
                setCurrentUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};