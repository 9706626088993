import { Calendar, MapPin, Link as LinkIcon, Plus, ExternalLink, Twitter, Instagram, Youtube, Facebook, Linkedin, Globe, PenLine, Check, Copy, Clock, AlertCircle, MapPinned } from 'lucide-react';
import { CreateNewEvent } from '@/types/Strings';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Event, EventResponse, ProfileData } from '@/types/api';
import { useAuth } from '@/components/AuthProvider';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ProfileResponse } from '~/functions/api/get-profile/[[username]]';
import UserNotFound from './UserNotFound';
import LoadingPage from './LoadingPage';
import ProfileFooter from '@/components/ProfileFooter';
import { EventCard } from '@/components/EventCard';

const PLATFORM_ICONS = {
  'Twitter': Twitter,
  'Instagram': Instagram,
  'YouTube': Youtube,
  'Facebook': Facebook,
  'LinkedIn': Linkedin,
  'Website': Globe,
  'TikTok': Globe,
};

export const UserPage = () => {
  const { user } = useAuth();
  const loggedInUsername = user?.username;
  const { username } = useParams();
  const [events, setEvents] = useState<Event[]>([]);
  const [profile, setProfile] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userFound, setUserFound] = useState(true);
  const [copied, setCopied] = useState(false);

  const isOwnProfile = loggedInUsername === username;

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    const fetchProfileAndEvents = async () => {
      try {
        const profileResponse = await fetch(`/api/get-profile/${username}`);
        if (!profileResponse.ok) {
          if (profileResponse.status === 404) {
            setUserFound(false);
            return;
          }
          throw new Error('Failed to fetch profile');
        }
        const profileData = await profileResponse.json() as ProfileResponse;
        setUserFound(true);
        setProfile(profileData);

        const eventsResponse = await fetch(`/api/get-events/${username}`);
        const eventsData: EventResponse = await eventsResponse.json();

        if (!eventsResponse.ok) {
          throw new Error(eventsData.error || 'Failed to fetch events');
        }

        setEvents(eventsData.events);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileAndEvents();
  }, [username]);

  if (!userFound) return <UserNotFound username={username || ''} />;
  if (loading) return <LoadingPage />;
  if (error) {
    return (
      <div className="min-h-screen flex flex-col bg-red-50">
        <main className="flex-1 pt-12 flex items-center justify-center">
          <div className="text-red-600 animate-bounce">{error}</div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-indigo-600">
      {/* Animated background patterns */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-[linear-gradient(45deg,rgba(255,255,255,0.1)_25%,transparent_25%,transparent_50%,rgba(255,255,255,0.1)_50%,rgba(255,255,255,0.1)_75%,transparent_75%,transparent)] bg-indigo-600 animate-pulse"></div>
        <div className="absolute -inset-96 opacity-50">
          <div className="absolute w-96 h-96 bg-pink-500 rounded-full mix-blend-multiply filter blur-xl animate-blob"></div>
          <div className="absolute w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
          <div className="absolute w-96 h-96 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
        </div>
      </div>

      <div className="relative max-w-5xl mx-auto px-4 py-12">
        {/* Profile Header */}
        <div className="relative bg-white rounded-3xl shadow-2xl p-8 mb-12 transform hover:scale-105 transition-all duration-300">
          <div className="relative flex flex-col items-center">
            <div className="absolute top-0 left-0 w-full h-32 bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 rounded-t-3xl"></div>

            <Avatar className="w-32 h-32 border-4 border-white shadow-2xl mb-4 relative z-10 transform hover:rotate-6 transition-transform duration-300">
              <AvatarImage src={profile?.avatar_url || ''} />
              <AvatarFallback className="bg-gradient-to-br from-pink-500 to-indigo-600 text-white text-3xl">
                {username?.[0]?.toUpperCase()}
              </AvatarFallback>
            </Avatar>

            <div className="flex items-center gap-3 mb-3">
              <h1 className="text-4xl font-black text-gray-900 bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 bg-clip-text text-transparent">
                {profile?.display_name || `@${username}`}
              </h1>
              <button
                onClick={handleCopyUrl}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-all hover:scale-110 hover:rotate-12"
                title="Copy profile URL"
              >
                {copied ? (
                  <Check className="w-4 h-4 text-green-600 animate-bounce" />
                ) : (
                  <Copy className="w-4 h-4 text-gray-600" />
                )}
              </button>
            </div>

            {profile?.bio && (
              <p className="text-gray-700 text-center mb-6 max-w-xl animate-fadeIn">
                {profile.bio}
              </p>
            )}

            {profile?.social_links && profile.social_links.length > 0 && (
              <div className="flex gap-4 mb-6">
                {profile.social_links.map((link, index) => {
                  const IconComponent = PLATFORM_ICONS[link.platform as keyof typeof PLATFORM_ICONS] || Globe;
                  return (
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-3 rounded-full bg-gradient-to-br from-pink-500 to-indigo-600 text-white hover:scale-125 hover:rotate-12 transition-all duration-300 shadow-lg hover:shadow-xl"
                      style={{ animationDelay: `${index * 100}ms` }}
                    >
                      <IconComponent className="w-5 h-5" />
                    </a>
                  );
                })}
              </div>
            )}

            {isOwnProfile && (
              <div className="flex gap-4">
                <Link
                  to="/create-event"
                  className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-indigo-600 text-white rounded-full font-black transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-110 hover:-rotate-3"
                >
                  <Plus className="h-5 w-5 mr-2 animate-spin-slow" />
                  {CreateNewEvent}
                </Link>
                <Link
                  to="/edit-user"
                  className="inline-flex items-center px-6 py-3 bg-gray-900 text-white rounded-full font-black transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-110 hover:rotate-3"
                >
                  <PenLine className="h-5 w-5 mr-2" />
                  Edit Profile
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Events Grid */}
        <div className="mb-12">
          <h2 className="text-3xl font-black text-white mb-6 drop-shadow-lg">
            Upcoming Events
          </h2>

          {events.length === 0 ? (
            <div className="bg-white rounded-3xl p-12 text-center transform hover:scale-105 transition-all duration-300">
              <AlertCircle className="w-16 h-16 text-indigo-400 mx-auto mb-4 animate-bounce" />
              <p className="text-gray-600 text-xl font-medium">No events coming up...</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {events.map((event, index) => (
                <div
                  key={event.id}
                  className="transform transition-all duration-300 hover:scale-105"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <EventCard event={event} />
                </div>
              ))}
            </div>
          )}
        </div>

        <ProfileFooter />
      </div>
    </div>
  );
};

export default UserPage;