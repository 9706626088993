import { Event } from "@/types/api";
import { Badge, Clock, ExternalLink, MapPinned, Music, Sparkles } from "lucide-react";
import AddToCalendar from "./AddToCalendar";
import EventLink from "./EventLink";

export const EventCard = ({ event }: { event: Event }) => {
    const formatEventTime = (dateStr: string) => {
        const date = new Date(dateStr);
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    const formatEventDate = (dateStr: string) => {
        const date = new Date(dateStr);
        return {
            month: date.toLocaleString('default', { month: 'short' }),
            day: date.getDate(),
            weekday: date.toLocaleString('default', { weekday: 'long' })
        };
    };

    const startDate = formatEventDate(event.start_datetime);
    const endDate = event.end_datetime ? formatEventDate(event.end_datetime) : null;
    const startTime = formatEventTime(event.start_datetime);
    const endTime = event.end_datetime ? formatEventTime(event.end_datetime) : null;

    return (
        <div className="group relative">
            <div className="bg-gradient-to-br from-violet-50 to-fuchsia-50 rounded-2xl shadow-lg group-hover:shadow-xl transition-all duration-300 overflow-hidden border-2 border-transparent group-hover:border-violet-200">
                {event.cover_image_url && (
                    <div className="w-full h-56 relative overflow-hidden">
                        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent z-10" />
                        <img
                            src={event.cover_image_url}
                            alt={event.title}
                            className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500 ease-out"
                        />
                        {event.is_verified && (
                            <div className="absolute top-4 right-4 z-20 flex items-center gap-1 bg-gradient-to-r from-emerald-400 to-teal-400 text-white px-3 py-1 rounded-full text-sm font-medium">
                                <Sparkles className="h-4 w-4" />
                                Verified
                            </div>
                        )}
                    </div>
                )}

                <div className="p-6">
                    <div className="flex justify-between items-start mb-4">
                        <div className="bg-gradient-to-br from-fuchsia-500 to-violet-600 rounded-xl px-4 py-2 text-white shadow-lg shadow-fuchsia-200">
                            <div className="text-2xl font-bold flex items-center justify-center gap-1.5">
                                <span className="text-fuchsia-100">{startDate.month}</span>
                                <span>{startDate.day}</span>
                            </div>
                            <div className="h-px bg-white/20 my-1.5 w-full"></div>
                            <div className="text-sm text-fuchsia-100">
                                {startDate.weekday}
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <AddToCalendar event={event} />
                            {event.event_url && <EventLink url={event.event_url} />}
                        </div>
                    </div>

                    <h2 className="text-xl font-bold text-gray-900 mb-4 group-hover:text-fuchsia-600 transition-colors">
                        {event.title}
                    </h2>

                    <div className="space-y-3">
                        <div className="flex items-center text-gray-700 bg-white/50 rounded-lg p-2 group-hover:bg-white/80 transition-colors">
                            <Clock className="h-4 w-4 mr-2 flex-shrink-0 text-fuchsia-500" />
                            <span className="text-sm font-medium">
                                {startTime}
                                {endTime && ` - ${endTime}`}
                            </span>
                        </div>

                        <div className="flex items-center text-gray-700 bg-white/50 rounded-lg p-2 group-hover:bg-white/80 transition-colors">
                            <MapPinned className="h-4 w-4 mr-2 flex-shrink-0 text-fuchsia-500" />
                            <span className="text-sm font-medium">{event.venue}</span>
                        </div>

                        {event.description && (
                            <p className="text-gray-600 text-sm line-clamp-2 mt-3 bg-white/50 rounded-lg p-2 group-hover:bg-white/80 transition-colors">
                                {event.description}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
};