import React from 'react';
import { Calendar } from 'lucide-react';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from '@/components/ui/button';
import { Event } from '@/types/api';

interface AddToCalendarProps {
    event: Event;
}

const AddToCalendar: React.FC<AddToCalendarProps> = ({ event }) => {
    const formatDate = (dateStr: string): string => {
        const date = new Date(dateStr);
        return date.toISOString().replace(/-|:|\.\d+/g, '');
    };

    const createGoogleCalendarUrl = (): string => {
        const params = new URLSearchParams({
            action: 'TEMPLATE',
            text: event.title,
            details: event.description || '',
            location: event.venue,
            dates: `${formatDate(event.start_datetime)}/${formatDate(event.end_datetime || event.start_datetime)}`
        });

        if (event.event_url) {
            params.append('details', `${params.get('details')}\n\nEvent URL: ${event.event_url}`);
        }

        return `https://calendar.google.com/calendar/render?${params.toString()}`;
    };

    const createICalFile = (): void => {
        const formatICalDate = (dateStr: string): string => {
            const date = new Date(dateStr);
            return formatDate(dateStr).slice(0, -4) + 'Z';
        };

        const description = event.event_url
            ? `${event.description || ''}\n\nEvent URL: ${event.event_url}`
            : event.description || '';

        const icalContent = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            `DTSTART:${formatICalDate(event.start_datetime)}`,
            `DTEND:${formatICalDate(event.end_datetime || event.start_datetime)}`,
            `SUMMARY:${event.title}`,
            `DESCRIPTION:${description.replace(/\n/g, '\\n')}`,
            `LOCATION:${event.venue}`,
            'END:VEVENT',
            'END:VCALENDAR'
        ].join('\r\n');

        const blob = new Blob([icalContent], { type: 'text/calendar;charset=utf-8' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${event.title.toLowerCase().replace(/\s+/g, '-')}.ics`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <AlertDialog>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <AlertDialogTrigger asChild>
                            <Button
                                variant="ghost"
                                className="rounded-full bg-violet-100 hover:bg-violet-200 transition-colors group-hover:rotate-6 duration-300 px-3 py-1.5 h-auto text-sm font-medium"
                            >
                                <Calendar className="h-4 w-4 text-violet-600 mr-1.5 inline-block" />
                                Add
                            </Button>
                        </AlertDialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Add to Calendar</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <AlertDialogContent className="w-full max-w-sm">
                <AlertDialogHeader>
                    <AlertDialogTitle>Add to Calendar</AlertDialogTitle>
                    <AlertDialogDescription>
                        Choose your preferred calendar service
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="flex-col gap-2 sm:flex-col">
                    <AlertDialogAction
                        className="w-full"
                        onClick={() => window.open(createGoogleCalendarUrl(), '_blank')}
                    >
                        Google Calendar
                    </AlertDialogAction>
                    <AlertDialogAction
                        className="w-full"
                        onClick={createICalFile}
                    >
                        iCal / Outlook
                    </AlertDialogAction>
                    <AlertDialogCancel className="w-full">Cancel</AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default AddToCalendar;