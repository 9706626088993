import { Music, UserX, ArrowRight } from 'lucide-react';
import { useAuth } from '@/components/AuthProvider';
import { Header } from '@/components/Header';

type UserNotFoundProps = {
    username: string
}
const UserNotFound = ({ username }: UserNotFoundProps) => {
    const { login } = useAuth();

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-purple-50">
            <Header />

            {/* Main Content */}
            <main className="pt-32 px-6">
                <div className="max-w-lg mx-auto text-center">
                    {/* Icon */}
                    <div className="mb-8 relative">
                        <div className="w-20 h-20 bg-purple-100 rounded-2xl flex items-center justify-center mx-auto">
                            <UserX className="h-10 w-10 text-purple-600" />
                        </div>
                    </div>

                    {/* Text Content */}
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        This page isn't playing yet
                    </h1>

                    <div className="mb-6">
                        <code className="text-lg bg-purple-100 text-purple-800 px-3 py-1 rounded-lg font-mono">
                            @{username}
                        </code>
                    </div>

                    <p className="text-gray-600 text-lg mb-8">
                        This username is available. Sign in to claim it and start sharing your upcoming gigs with your friends and fans.
                    </p>

                    {/* CTA Button */}
                    <button
                        onClick={login}
                        className="bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-all transform hover:scale-105 shadow-lg hover:shadow-purple-200 inline-flex items-center"
                    >
                        Claim this username
                        <ArrowRight className="ml-2 h-5 w-5" />
                    </button>

                    {/* Additional Info */}
                    <div className="mt-12 pt-8 border-t border-purple-100">
                        <p className="text-gray-500">
                            Looking for a different artist? Make sure you have the right username.
                        </p>
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer className="fixed bottom-0 w-full border-t bg-white/80 backdrop-blur-sm">
                <div className="max-w-6xl mx-auto px-6 h-14 flex items-center justify-center">
                    <div className="flex items-center space-x-6 text-sm">
                        <a href="/" className="text-gray-600 hover:text-purple-600 transition-colors">
                            Home
                        </a>
                        <div className="flex items-center">
                            <span className="text-purple-600 mr-2">●</span>
                            <span className="text-gray-600">Always free for artists</span>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default UserNotFound;

// Usage:
// <UserNotFound username="requestedUsername" />