
export const convertToUTC = (dateTimeStr: string, timezone: string): string => {
    if (!dateTimeStr) return '';

    // Create a date object from the local datetime string
    const date = new Date(dateTimeStr);

    // Format in target timezone
    return date.toISOString();
};

export const getDefaultStartTime = (): string => {
    const now = new Date();
    // Round to next 30 minutes
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30);
    now.setSeconds(0);
    now.setMilliseconds(0);

    // Format for datetime-local input in local timezone
    return now.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).replace(' ', 'T');
};

export const calculateEndTime = (startTimeStr: string): string => {
    if (!startTimeStr) return '';
    const startTime = new Date(startTimeStr);
    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add 1 hour

    // Format for datetime-local input in local timezone
    return endTime.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).replace(' ', 'T');
};