import React from 'react';
import { Avatar, AvatarImage } from '@/components/ui/avatar';

const Testimonials = ({
    testimonials = [
        {
            text: "The simplicity is what makes it great. One link for everything - exactly what artists need.",
            author: "Maria Rodriguez",
            role: "Classical Violinist",
            avatar: "https://upload.wikimedia.org/wikipedia/commons/9/9e/20200229_03_Alcest_%2849605436357%29_%28cropped%29.jpg"
        },
        {
            text: "This platform has completely transformed how I promote my gigs. My audience always knows where to find me now! The response from my fans has been incredible.",
            author: "Alex Chen",
            role: "Jazz Pianist",
            avatar: "https://upload.wikimedia.org/wikipedia/commons/9/9e/20200229_03_Alcest_%2849605436357%29_%28cropped%29.jpg"
        },
        {
            text: "Finally, a platform that understands what working artists actually need. This has saved me so much time!",
            author: "James Wilson",
            role: "Blues Guitarist",
            avatar: "https://upload.wikimedia.org/wikipedia/commons/9/9e/20200229_03_Alcest_%2849605436357%29_%28cropped%29.jpg"
        }
    ],
    title = "What artists are saying"
}) => {
    return (
        <div className="mt-32 mb-24">
            <h2 className="text-3xl font-bold text-center mb-16">{title}</h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center max-w-6xl mx-auto px-4">
                {/* Side testimonials use the same styling */}
                {testimonials.map((testimonial, idx) => (
                    <div
                        key={testimonial.author}
                        className={`bg-white rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl
                            ${idx === 1 ?
                                'p-8 md:-my-8 transform hover:scale-105' : // Middle card
                                'p-6 transform hover:scale-105'             // Side cards
                            }`}
                    >
                        <div className={`mb-6 ${idx === 1 ? 'text-lg' : 'text-base'}`}>
                            <blockquote className="text-gray-700 leading-relaxed">
                                "{testimonial.text}"
                            </blockquote>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Avatar className={idx === 1 ? "w-14 h-14" : "w-12 h-12"}>
                                <AvatarImage src={testimonial.avatar} />
                            </Avatar>
                            <div>
                                <div className={`font-medium text-gray-900 ${idx === 1 ? 'text-lg' : 'text-base'}`}>
                                    {testimonial.author}
                                </div>
                                <div className="text-purple-600 text-sm">
                                    {testimonial.role}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonials;